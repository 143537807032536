define("discourse/plugins/discourse-form-processor/discourse/controllers/forms-submit-post", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import discourseComputed from "discourse-common/utils/decorators";
  var _default = _exports.default = _controller.default.extend({
    router: (0, _service.inject)()
  });
});