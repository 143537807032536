define("discourse/plugins/discourse-form-processor/discourse/templates/forms-submit-get", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    Youcan't get a form.
  */
  {
    "id": "7UiONc8Z",
    "block": "[[[1,\"Youcan't get a form.\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-form-processor/discourse/templates/forms-submit-get.hbs",
    "isStrictMode": false
  });
});