define("discourse/plugins/discourse-form-processor/discourse/routes/forms-submit-get", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    // setupController(controller, model) {
    //   controller.set("forms", { form: "yes" });
    // },
  });
});